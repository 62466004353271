import * as React from "react";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import "./index.scss";
export default function BasicTooltip({ item, data }) {
  const CustomizedToolTip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "rgba(63,70,49,0.9)",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "rgba(63,70,49,0.9)",
      color: "white",
      boxShadow: theme.shadows[0],
    },
  }));

  return (
    <div className="custom-tooltip-container">
      <CustomizedToolTip title={data} arrow>
        {item}
      </CustomizedToolTip>
    </div>
  );
}
