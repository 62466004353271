import { debounce } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import {
  createGalleryProduct,
  registerBrandSale,
} from "src/api/dashboard/gallery";
import { searchSpaceCustomers } from "src/api/dashboard/space";
import ActionButton from "src/modules/dashboard/components/ActionButton";
import Input from "src/modules/dashboard/components/Input";
import ModalLayout from "src/modules/dashboard/components/ModalLayout";
import { queryClient } from "src/utils";
import Lottie from "react-lottie";
import SimpleLoader from "src/assets/lotties/simple-loader.json";
import SelectGalleryProducts from "./SelectGalleryProducts";
import "./index.scss";
import dayjs from "dayjs";
import { paymentMethods } from "src/utils/constants";

const RegisterNewSaleModal = ({ isVisible, setIsVisible, brandId }) => {
  const [saleDetails, setSaleDetails] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  const handleSearchInput = async (e) => {
    setIsLoading(true);
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
  };

  const searchForCustomers = async () => {
    try {
      const fetchedCustomers = await searchSpaceCustomers({
        searchQuery: searchTerm.trim(),
      });
      setCustomers(fetchedCustomers);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    debounceHandleSearch();
    return () => {
      debounceHandleSearch.cancel();
    };
  }, [searchTerm]);

  const debounceHandleSearch = useMemo(
    () => debounce(searchForCustomers, 400),
    [searchTerm]
  );

  useEffect(() => {
    setSaleDetails({
      customerId: selectedCustomer?._id,
      date: dayjs().format(),
      paymentMethod: selectedPaymentMethod,
      items: selectedItems,
    });
  }, [selectedCustomer, selectedItems, selectedPaymentMethod]);

  return (
    <>
      <ModalLayout
        show={isVisible}
        onAbort={() => {
          setIsVisible(false);
          setSelectedCustomer(null);
          setSelectedPaymentMethod(null);
          setSelectedItems([]);
          setSaleDetails({});
        }}
        title={`Create New Sale`}
        className="register-new-sale-modal"
      >
        {!selectedCustomer ? (
          <div>
            <Input
              value={searchTerm}
              onChange={handleSearchInput}
              name={"name"}
              placeholder="Search by id, name, phone, email"
            />
            <table className="search-results-table">
              {customers?.length !== 0
                ? customers?.map((customer, i) => (
                    <tr key={i}>
                      <td>#{customer.customerId}</td>
                      <td>{customer.name}</td>
                      <td>
                        <span className="phone-cell">
                          <span>{customer.countryCode}</span>
                          {customer.phone}
                        </span>
                      </td>
                      <td>
                        <ActionButton
                          onClick={() => {
                            setSelectedCustomer(customer);
                            setSearchTerm("");
                          }}
                          style={{
                            paddingTop: 2,
                            paddingBottom: 2,
                            margin: 0,
                          }}
                          title="Select"
                          backgroundColor="colorPrimaryLight"
                        />
                      </td>
                    </tr>
                  ))
                : isLoading && (
                    <Lottie
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: SimpleLoader,
                        rendererSettings: {
                          preserveAspectRatio: "xMidYMid slice",
                        },
                      }}
                      width={30}
                      height={30}
                    />
                  )}
            </table>
          </div>
        ) : (
          <div>
            <div className="customer-data">
              <div className="customer-data-item">
                <span>Name: </span>
                <span>{selectedCustomer?.name}</span>
              </div>
              <div className="customer-data-item">
                <span>Phone: </span>
                <span>{selectedCustomer?.phone}</span>
              </div>
            </div>
            <div className="line-separator" />
            <SelectGalleryProducts
              brandId={brandId}
              onChange={(selectedItems) => {
                setSelectedItems(selectedItems);
              }}
            />
            {selectedItems.length !== 0 && (
              <>
                <div className="line-separator" />
                <h5>Select Payment Method</h5>
                <div className="payment-methods">
                  {Object.values(paymentMethods).map((paymentMethod) => (
                    <span
                      className={
                        selectedPaymentMethod === paymentMethod
                          ? "selected"
                          : ""
                      }
                      onClick={() => setSelectedPaymentMethod(paymentMethod)}
                    >
                      {paymentMethod}
                    </span>
                  ))}
                </div>
              </>
            )}

            <ActionButton
              onClick={async () => {
                if (await registerBrandSale(brandId, saleDetails)) {
                  await queryClient.invalidateQueries({
                    queryKey: ["galleryBrandSales", brandId],
                  });
                  await queryClient.invalidateQueries({
                    queryKey: ["brandProducts", { brandId }],
                  });
                  await queryClient.invalidateQueries({
                    queryKey: ["galleryStatistics", brandId],
                  });

                  setIsVisible(false);
                  setSelectedCustomer(null);
                  setSaleDetails({});
                  setSelectedItems([]);
                  setSelectedPaymentMethod(null);
                }
              }}
              title={"Create Sale"}
              isActive={
                selectedCustomer != null &&
                selectedItems.length !== 0 &&
                selectedPaymentMethod != null
              }
              style={{
                paddingTop: 10,
                paddingBottom: 10,
                fontSize: 16,
                fontFamily: "Aoboshi One",
                margin: "auto",
                marginTop: 30,
              }}
              backgroundColor="colorSecondaryDarker"
            />
          </div>
        )}
      </ModalLayout>
    </>
  );
};

export default RegisterNewSaleModal;
