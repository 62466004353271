import React, { useState } from "react";
import MainDashboardLayout from "../../layouts/MainDashboardLayout";
import { FaPlus } from "react-icons/fa";
import "./index.scss";
import DataTable from "../../components/InfiniteDataTable";
import ActionButton from "../../components/ActionButton";
import { getKitchenProducts } from "src/api/dashboard/kitchen";
import BasicTooltip from "../../components/BasicTooltip";
import dayjs from "dayjs";
import AddProductModal from "./components/AddProductModal";
import RestockKitchenProductModal from "./components/RestockKitchenProductModal";
import { GuardProvider } from "src/contexts/GuardContext";

const Kitchen = () => {
  const [product, setProduct] = useState();
  const [isAddProductModalVisible, setIsAddProductModalVisible] =
    useState(false);
  const [isRestockKitchenModalVisible, setIsRestockKitchenModalVisible] =
    useState(false);
  return (
    <MainDashboardLayout slug={"Kitchen"}>
      <GuardProvider allowedUsers={["superAdmin", "receptionist"]}>
        <div className=" kitchen-container">
          <div className="side-kitchen">
            <button
              className="new-customer-btn"
              onClick={() => {
                setIsAddProductModalVisible(true);
              }}
            >
              New Product <FaPlus className="white m-l-2" />
            </button>
          </div>
          <div className="table-container">
            <DataTable
              dataKey="kitchenProducts"
              fetchData={getKitchenProducts}
              columns={[
                {
                  title: "Name",
                  selector: (row) => row?.title ?? "N/A",
                },
                {
                  title: "Category",
                  selector: (row) => row?.category ?? "N/A",
                },
                {
                  title: "Price",
                  selector: (row) => row?.price ?? "N/A",
                },
                {
                  title: "Stock",
                  selector: (row) => row?.remainingStock ?? "N/A",
                },
                {
                  title: "Stock History",
                  selector: (row) => (
                    <div className="tooltip-container ">
                      {row?.restocks
                        ?.slice(
                          Math.max(
                            row?.restocks?.length -
                              (row?.restocks?.length > 4 ? 4 : 20),
                            1
                          )
                        )
                        ?.map((item) => (
                          <BasicTooltip
                            item={
                              <div className="item">
                                {dayjs(item.date).format("DD MMM")}
                              </div>
                            }
                            data={
                              <div>
                                CostPerItem: {item.costPerItem} <br />
                                Quantity: {item.quantity}
                                <br />
                                Date: {dayjs(item.date).format("DD/MM/YY")}
                              </div>
                            }
                          />
                        ))}
                    </div>
                  ),
                },
              ]}
              actions={[
                {
                  component: (row) => (
                    <ActionButton
                      onClick={() => {
                        setProduct(row);
                        setIsRestockKitchenModalVisible(true);
                      }}
                      title="Stock Up"
                      backgroundColor="colorPrimaryLight"
                    />
                  ),
                },
              ]}
              tabs={[
                {
                  title: "Stock",

                  filter: {
                    status: "Stock",
                  },
                },
                {
                  title: "Stocktaking",

                  filter: {
                    status: "Stocktaking",
                  },
                },
              ]}
            />
          </div>
        </div>

        <AddProductModal
          isVisable={isAddProductModalVisible}
          setIsVisible={setIsAddProductModalVisible}
        />

        <RestockKitchenProductModal
          isVisible={isRestockKitchenModalVisible}
          setIsVisible={setIsRestockKitchenModalVisible}
          product={product}
        />
      </GuardProvider>
    </MainDashboardLayout>
  );
};

export default Kitchen;
