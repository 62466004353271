import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";

import "./index.scss";

const Select = ({
  onChange,
  value,
  options,
  defaultTitle,
  style = {},
  isTitleDisabled = true,
  defaultValue,
  hideArrow,
}) => {
  return (
    <div className="select-container" style={style}>
      <select
        onChange={(e) => onChange(e.target.value)}
        value={value}
        defaultValue={defaultValue}
      >
        {defaultTitle && (
          <option value={""} disabled={isTitleDisabled}>
            {defaultTitle}
          </option>
        )}
        {options?.map(({ label, value }, index) => (
          <option key={index} value={value}>
            {label}
          </option>
        ))}
      </select>
      <IoIosArrowDown
        size={15}
        color="white"
        className={hideArrow ? "" : "arrow-down"}
      />
    </div>
  );
};

export default Select;
