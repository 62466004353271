export const URLs = {
  SPACE_LOCATION: "https://maps.app.goo.gl/M1T5Y4vC1yiwJrDC8",
  INSTAGRAM_PROFILE:
    "https://www.instagram.com/auraspace.eg/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA==",
};

export const paymentMethods = {
  CASH: "cash",
  VISA: "visa",
  INSTAPAY: "instaPay",
  MOBILE_WALLET: "mobileWallet",
  TELDA: "telda",
};

export const expenseCategories = {
  KITCHEN: "kitchen",
  BILLS: "bills",
  OPERATIONS: "operations",
  CLEANING: "cleaning",
  MAINTENANCE: "maintenance",
  DECORATIONS: "decorations",
  RENOVATIONS: "renovations",
  ASSETS: "assets",
  MARKETING: "marketing",
  SALARIES: "salaries",
  GALLERY: "gallery",
};

export const galleryDealTypes = {
  SPACE_PRICE: "spacePrice",
  DISCOUNT: "discount",
};

export const brandSaleStatuses = {
  PAID: "paid",
  DISCOUNT: "discount",
};
