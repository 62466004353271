import React from "react";
import "./index.scss";
import Navbar from "src/modules/dashboard/components/Navbar";
import AuraLogo from "src/assets/images/aura-word-black.png";
import Loader from "src/modules/space/components/Loader";

const MainDashboardLayout = ({
  children,
  slug,
  extraHeaderContent,
  className = "",
  isLoading = false,
}) => {
  return (
    <div className="main-dashboard-layout">
      <div className="dashboard-header">
        <Navbar />
      </div>
      <div className="dashboard-body">
        <div className="header-container">
          {slug ? (
            <h5 className="dashboard-title">
              Dashboard<span> / {slug}</span>
            </h5>
          ) : (
            <div />
          )}
          {!isLoading && extraHeaderContent && extraHeaderContent}
          <img className="aura-logo" src={AuraLogo} alt="" />
        </div>
        <div className={`dashboard-content ${className}`}>
          {isLoading ? <Loader /> : children}
        </div>
      </div>
    </div>
  );
};

export default MainDashboardLayout;
