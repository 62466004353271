import { execute } from "src/api";
import { API_URL_SPACE } from "src/utils";

export const registerEventForm = (values, formId) =>
  execute({
    method: "POST",
    url: `${API_URL_SPACE}/forms/${formId}/register `,
    body: values,
  });
export const getFormDetails = (formId) =>
  execute({
    method: "GET",
    url: `${API_URL_SPACE}/forms/${formId} `,
  });
