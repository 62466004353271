import React, { useState } from "react";
import { createGalleryProduct } from "src/api/dashboard/gallery";
import ActionButton from "src/modules/dashboard/components/ActionButton";
import Input from "src/modules/dashboard/components/Input";
import ModalLayout from "src/modules/dashboard/components/ModalLayout";
import { queryClient } from "src/utils";
const CreateGalleryProductModal = ({ isVisible, setIsVisible, brandId }) => {
  const [productDetails, setProductDetails] = useState({});

  return (
    <>
      <ModalLayout
        show={isVisible}
        onAbort={() => {
          setIsVisible(false);
        }}
        title={`Create new product`}
      >
        <Input
          placeholder={"Title"}
          onChange={(e) => {
            setProductDetails({
              ...productDetails,
              title: e.target.value,
            });
          }}
          name={"title"}
          type="text"
        />
        <Input
          placeholder={"Original Price"}
          onChange={(e) => {
            setProductDetails({
              ...productDetails,
              originalPrice: parseFloat(e.target.value),
            });
          }}
          name={"originalPrice"}
          trailingLabel={"EGP"}
          type="number"
          showPlaceholderOutside
          min={1}
        />
        <Input
          placeholder={"Selling Price"}
          onChange={(e) => {
            setProductDetails({
              ...productDetails,
              sellingPrice: parseFloat(e.target.value),
            });
          }}
          name={"sellingPrice"}
          trailingLabel={"EGP"}
          type="number"
          showPlaceholderOutside
          min={1}
        />
        <Input
          onChange={(e) => {
            setProductDetails({
              ...productDetails,
              initialStock: parseInt(e.target.value),
            });
          }}
          placeholder={"Initial Stock"}
          name={"initialStock"}
          type="number"
          min={1}
        />
        <ActionButton
          onClick={async () => {
            if (await createGalleryProduct(brandId, productDetails)) {
              await queryClient.invalidateQueries({
                queryKey: ["brandProducts", { brandId }],
              });
              await queryClient.invalidateQueries({
                queryKey: ["galleryStatistics", brandId],
              });

              setIsVisible(false);
            }
          }}
          title={"Create"}
          style={{
            paddingTop: 10,
            paddingBottom: 10,
            fontSize: 16,
            fontFamily: "Aoboshi One",
            margin: "auto",
            marginTop: 30,
          }}
          backgroundColor="colorSecondaryDarker"
        />
      </ModalLayout>
    </>
  );
};

export default CreateGalleryProductModal;
