import { API_URL_SPACE } from "src/utils";

const { execute } = require("src/api");

export const payForEventTicket = (data, eventId) =>
  execute({
    method: "POST",
    url: `${API_URL_SPACE}/events/${eventId}/pay`,
    body: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const getEventDetails = (eventId) =>
  execute({
    method: "GET",
    url: `${API_URL_SPACE}/events/${eventId}`,
  }).then((data) => data?.event);

export const getEventTicketDetails = ({ eventId, ticketId }) =>
  execute({
    method: "GET",
    url: `${API_URL_SPACE}/events/${eventId}/tickets/${ticketId}`,
  }).then((data) => data?.ticket);

export const listEventTickets = ({ eventId }) =>
  execute({
    method: "GET",
    url: `${API_URL_SPACE}/events/${eventId}/tickets`,
  }).then((data) => data?.tickets);

export const listEvents = () =>
  execute({
    method: "GET",
    url: `${API_URL_SPACE}/events`,
  }).then((data) => data?.events);
