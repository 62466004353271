import { QueryClient } from "react-query";
import { toast } from "react-toastify";

export const storageKeys = {
  USER: "user",
  ACCESS_TOKEN: "accessToken",
  DURATION_RECEPTION_SPACE_PASSES: "durationReceptionSpacePasses", // For the duration select element on the receptione
};

export const API_URL_DASHBOARD = "/api/dashboard";
export const API_URL_SPACE = "/api/space";

export const notify = (notficationMessage, type = "success") => {
  switch (type) {
    case (type = "error"):
      toast.error(notficationMessage);
      break;
    case (type = "success"):
      toast.success(notficationMessage);
      break;
    default:
  }
};

export const queryClient = new QueryClient();

export const knowAboutUsSources = {
  RETURNING_CUSTOMER: "returningCustomer",
  PASSING_BY: "passingBy",
  FRIEND: "friend",
  INSTAGRAM: "instagram",
  TIKTOK: "tiktok",
  HOTDESK: "hotdesk",
  GOOGLE_MAPS: "googleMaps",
  PACKAGE: "package",
};

export const kitchenProductCategories = {
  PASTRIES: "pastries",
  SOFT_DRINKS: "soft-drinks",
  HOT_DRINKS: "hot-drinks",
  SNACKS: "snacks",
};

export const paymentTransactionTypes = {
  EVENT_TICKET: "eventTicket",
  WORKSHOP_TICKET: "workshopTicket",
  SPACE_PASS: "spacePass",
  KITCHEN_PURCHASE: "kitchenPurchase",
  OFFLINE_GALLERY_PURCHASE: "offlineGalleryPurchase",
};
