import React from "react";
import Modal from "react-bootstrap/Modal";
import "./index.scss";
const ModalLayout = ({ children, title, className, onAbort, ...props }) => {
  return (
    <Modal
      className="model"
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onAbort}
      onEscapeKeyDown={onAbort}
    >
      <Modal.Header closeButton className="model-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <div className={className}>
          <h4 className="title">{title}</h4>
          {children}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ModalLayout;
