import { executeWithAuth } from "src/api";
import { API_URL_DASHBOARD } from "src/utils";

export const getGalleryBrands = () =>
  executeWithAuth({
    method: "GET",
    url: `${API_URL_DASHBOARD}/gallery/brands`,
  }).then((data) => data?.brands || []);

export const getBrandProducts = ({ brandId }) =>
  executeWithAuth({
    method: "GET",
    url: `${API_URL_DASHBOARD}/gallery/${brandId}/products`,
  }).then((data) => data?.products || []);

export const getBrandSales = ({ brandId }) =>
  executeWithAuth({
    method: "GET",
    url: `${API_URL_DASHBOARD}/gallery/${brandId}/sales`,
  }).then((data) => data?.sales || []);

export const getBrandStatistics = (brandId) =>
  executeWithAuth({
    method: "GET",
    url: `${API_URL_DASHBOARD}/gallery/${brandId}/statistics`,
  }).then((data) => data?.statistics);

export const registerBrandSale = (brandId, saleDetails) =>
  executeWithAuth({
    method: "POST",
    notifySuccessMsg: true,
    url: `${API_URL_DASHBOARD}/gallery/${brandId}/sales`,
    body: saleDetails,
  }).then((data) => data?.sale);

export const returnBrandSale = (saleId) =>
  executeWithAuth({
    method: "POST",
    notifySuccessMsg: true,
    url: `${API_URL_DASHBOARD}/gallery/sales/${saleId}/return`,
  }).then((data) => data?.sale);

export const createGalleryProduct = (brandId, productDetails) =>
  executeWithAuth({
    method: "POST",
    notifySuccessMsg: true,
    url: `${API_URL_DASHBOARD}/gallery/${brandId}/products`,
    body: productDetails,
  }).then((data) => data?.product);

export const stockupGalleryProduct = (
  productId,
  { quantity, originalPrice, sellingPrice }
) =>
  executeWithAuth({
    method: "POST",
    notifySuccessMsg: true,
    url: `${API_URL_DASHBOARD}/gallery/products/${productId}/stockup`,
    body: { quantity, originalPrice, sellingPrice },
  }).then((data) => data?.product);
