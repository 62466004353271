import { FaCheck } from "react-icons/fa";
import "./index.scss";
const SuccessEventModal = (eventDetails) => {
  return (
    <div>
      <div className="success">
        <div className="check-icon">
          <FaCheck />
        </div>
        <h2>Sent Successfully</h2>
        <p> We will send you the ticket link after we confirm the payment </p>
      </div>
    </div>
  );
};

export default SuccessEventModal;
