import React from "react";
import LeftVendor from "src/assets/images/Left-vector.png";
import CommingSoon from "src/assets/images/Comming-soon.png";
import AuraLogo from "src/assets/images/aura-word-of-1.png";
import { FaLocationDot } from "react-icons/fa6";
import { FiInstagram } from "react-icons/fi";
import { URLs } from "src/utils/constants";
import "./index.css";
import { Link } from "react-router-dom";
export default function HomeScreen() {
  return (
    <div className="home   text-center  ">
      <div className="left-vector   text-start  ">
        <img src={LeftVendor} alt="" />
      </div>
      <div className=" text-center aura-container ">
        <h1>Aura Space is now open</h1>
        <div className=" d-inline-block">9 </div>
        <h3 className=" d-inline-block mx-1"> street </h3> 251, 
        <h3 className=" d-inline-block mx-1">Degla Maadi </h3>
        <div>
           
          <img src={AuraLogo} alt="" /> <br />
          <h4>Your spiritual space for creativity</h4>
          <div>
            <Link to={URLs.SPACE_LOCATION} target="_blank">
               
              <button className="call-to-action-btn">
                Google Maps
                <FaLocationDot className="ms-2" /> 
              </button>
            </Link>
            <Link to={URLs.INSTAGRAM_PROFILE} target="_blank">
              <button className="call-to-action-btn">
                <FiInstagram className="" /> auraspace.eg
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="comming-soon px-5 text-lg-start text-md-start    ">
        <img src={CommingSoon} alt="" />
      </div>
      <div className="right-vector">
        <img src={LeftVendor} alt="" />
      </div>
    </div>
  );
}
