import React from "react";
import "./index.css";
import MenuTitle from "src/assets/images/menu.png";
import RightVectorWithTitle from "src/assets/images/right-vector-with-aura.png";
import LeftVectorWithTitle from "src/assets/images/left-vector-with-aura.png";
import menuPharse from "src/assets/images/menu-pharse.png";
import { Helmet } from "react-helmet";
import { useQuery } from "react-query";
import { getKitchenProducts } from "src/api/space/global";
const KitchenMenu = () => {
  const { data: kitchenProducts } = useQuery({
    queryKey: "kitchenProducts",
    queryFn: getKitchenProducts,
  });

  return (
    <div className="menu text-center position-relative">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Aura Space | Kitchen Menu</title>
        <meta property="title" content="Aura Space | Kitchen Menu" data-rh="true" />
        <meta
          property="og:description"
          content="The kitchen menu for Aura Space"
        />
        <meta property="og:image" content="https://i.ibb.co/7Y7v60C/aura-space-kitchen-menu.png" />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div className="right-vector-with-aura  ">
        <img src={RightVectorWithTitle} alt="" />
      </div>
      <div className="left-vector-with-aura ">
        <img src={LeftVectorWithTitle} alt="" width={200} />
      </div>
      <div>
        <img src={MenuTitle} alt="" width={150} className="  mt-3" />
      </div>

      <div className="container mt-5 ">
        <div className="row">
          <div className="col-md-3 ">
            <h2 className=" mb-md-5 mb-3">Snacks</h2>

            {kitchenProducts
              ?.filter((item) => item.category === "snacks")
              .map((snacks) => (
                <div className=" row ">
                  <h5 className="   offset-1 col-5 col-md-6 offset-md-0  text-start     ">
                    {snacks.title}
                  </h5>
                  <h5 className=" col-6">{snacks.price}</h5>
                </div>
              ))}
          </div>
          <div className="col-md-3">
            <h2 className=" mb-md-5 mb-3">Fridge</h2>
            {kitchenProducts
              ?.filter((item) => item.category === "soft-drinks")
              .map((snack) => (
                <div className=" row ">
                  <h5 className="   offset-1 col-5 col-md-6 offset-md-0  text-start     ">
                    {snack.title}
                  </h5>
                  <h5 className=" col-6">{snack.price}</h5>
                </div>
              ))}
          </div>
          <div className="col-md-3">
            <h2 className=" mb-md-5 mb-3">Hot Drinks</h2>
            {kitchenProducts
              ?.filter((item) => item.category === "hot-drinks")
              .map((snack) => (
                <div className=" row ">
                  <h5 className="   offset-1 col-5 col-md-6 offset-md-0  text-start     ">
                    {snack.title}
                  </h5>
                  <h5 className=" col-6">{snack.price}</h5>
                </div>
              ))}
          </div>
          <div className="col-md-3">
            <h2 className=" mb-md-5 mb-3">Pastries</h2>
            {kitchenProducts
              ?.filter((item) => item.category === "pastries")
              .map((snack) => (
                <div className=" row ">
                  <h5 className="   offset-1 col-5 col-md-6 offset-md-0  text-start     ">
                    {snack.title}
                  </h5>
                  <h5 className=" col-6">{snack.price}</h5>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="menu-pharse">
        <img src={menuPharse} alt="" className=" ms-5" />
      </div>
    </div>
  );
};

export default KitchenMenu;
