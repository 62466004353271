import React, { useState } from "react";
import "./index.scss";
import SimpleLoader from "src/assets/lotties/simple-loader.json";
import Lottie from "react-lottie";

const ActionButton = ({
  title,
  style,
  backgroundColor,
  onClick,
  isActive = true,
  className,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <button
      onClick={async () => {
        if (isLoading || !isActive) {
          return;
        }
        try {
          setIsLoading(true);
          onClick && (await onClick());
        } finally {
          setIsLoading(false);
        }
      }}
      style={{
        ...style,
        cursor: isLoading ? "progress" : isActive ? "pointer" : "not-allowed",
      }}
      className={`${backgroundColor}-bg action-button ${
        isActive === true ? "" : "bg-gray"
      } ${className ?? className}`}
    >
      {isLoading ? (
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: SimpleLoader,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          width={25}
          height={25}
        />
      ) : (
        title
      )}
    </button>
  );
};
export default ActionButton;
