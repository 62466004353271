import { useParams } from "react-router-dom";
import MainDashboardLayout from "../../layouts/MainDashboardLayout/index";
import ActionButton from "../../components/ActionButton";
import { MdEdit } from "react-icons/md";
import "./index.scss";
import DataTable from "../../components/InfiniteDataTable";
import StatsNumberBox from "../../components/StatsNumberBox";
import { useQuery } from "react-query";
import { getEventDetails } from "src/api/space/events";
import Loader from "src/modules/space/components/Loader";
import dayjs from "dayjs";
import {
  listEventsCancelledTransactions,
  listEventsPendingTransactions,
  listEventTickets,
} from "src/api/dashboard/events";
import { FaEye } from "react-icons/fa";
import { IoClose } from "react-icons/io5";

import { useState } from "react";
import ImageViewer from "react-simple-image-viewer";
import { usePrompt } from "src/contexts/PromptContext";
import {
  cancelPaymentTransaction,
  confirmPaymentTransaction,
} from "src/api/dashboard/accounting";
import { queryClient } from "src/utils";
import { GuardProvider } from "src/contexts/GuardContext";

const EventTickets = () => {
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [selectedScreenshot, setSelectedScreenshot] = useState(null);

  const { eventId } = useParams();
  const { showPrompt, closePrompt } = usePrompt();

  const { data: event, isLoading } = useQuery(["event", eventId], () =>
    getEventDetails(eventId)
  );

  return (
    <MainDashboardLayout
      slug={`Events / ${eventId}`}
      className="event-tickets-page"
    >
      <GuardProvider
        allowedUsers={["superAdmin", "receptionist", "eventManager"]}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="event-tickets-side">
              <div className="title-label">Details</div>
              <div className="details-box">
                <div className="header">
                  <p>#{event?.eventId}</p>
                  <span />
                  <p>{event?.price}L.E</p>
                  <span />
                  <p>{dayjs(event?.date).format("dddd, DD MMMM")}</p>
                  <span />
                  <p>{dayjs(event?.date).format("hh:mma")}</p>
                </div>
                <h2 className="event-title">{event?.title}</h2>
                <p className="event-description">{event?.description}</p>
                <ActionButton
                  className="edit-btn"
                  backgroundColor="colorPrimaryLight"
                  title={
                    <>
                      <span>Edit </span>
                      <MdEdit style={{ marginLeft: 5 }} />
                    </>
                  }
                />
              </div>
              <br />
              <br />
              <br />
              <div className="title-label">Statistics</div>
              <div className="stats-boxes">
                <StatsNumberBox
                  title="Sold Tickets"
                  mainNumber={
                    event.seats - event.remainingSeats + "/" + event.seats
                  }
                  numberIdentifier="ticket"
                />
                <br />
                <StatsNumberBox
                  title="Total Sales"
                  mainNumber={
                    (event.seats - event.remainingSeats) * event.price
                  }
                  numberIdentifier="L.E"
                />
                <br />
              </div>
            </div>
            <div className="event-tickets-tables">
              <div className="title-label">Tickets</div>
              <DataTable
                dataKey={["eventTickets", event._id]}
                fetchData={() => listEventTickets(event._id)}
                columns={[
                  { title: "Ticket Id", selector: (row) => row?.ticketId },
                  { title: "Name", selector: (row) => row?.customer?.name },
                  { title: "Phone", selector: (row) => row?.customer?.phone },
                  { title: "Email", selector: (row) => row?.customer?.email },
                  {
                    title: "Time",
                    selector: (row) => (
                      <>
                        <span style={{ fontSize: 8 }}>
                          {dayjs(row.createdAt).format("DD MMM")}
                        </span>{" "}
                        <span style={{ fontWeight: 500, fontSize: 10 }}>
                          {dayjs(row.createdAt).format("hh:mm a")}
                        </span>
                      </>
                    ),
                  },
                  { title: "Seats", selector: (row) => row?.ticketSeats },
                  {
                    title: "Payment Method",
                    selector: (row) => (
                      <div
                        className={`payment-method-cell-${row?.paymentTransaction?.paymentMethod}`}
                        onClick={() => {
                          if (
                            row?.paymentTransaction?.additionalInfo
                              ?.screenshotUrl
                          ) {
                            setSelectedScreenshot(
                              row?.paymentTransaction?.additionalInfo
                                ?.screenshotUrl
                            );
                            setIsImageViewerOpen(true);
                          }
                        }}
                      >
                        {row?.paymentTransaction?.paymentMethod}
                      </div>
                    ),
                  },
                ]}
                actions={[
                  {
                    component: (row) => (
                      <ActionButton
                        onClick={() => {
                          showPrompt({
                            title: "Refund Ticket",
                            message: `Are you sure you want to refund ${row?.paymentTransaction?.amount}L.E for ${row?.customer?.name}?`,
                            onConfirm: async () => {
                              // Cancel the ticket
                              await cancelPaymentTransaction(
                                row?.paymentTransaction?._id
                              );
                              // Invalidate queries in the page
                              await queryClient.invalidateQueries({
                                queryKey: [["eventTickets", event._id]],
                              });
                              await queryClient.invalidateQueries({
                                queryKey: [
                                  ["eventCancelledTransactions", event._id],
                                ],
                              });
                              await queryClient.invalidateQueries({
                                queryKey: ["event", eventId],
                              });

                              closePrompt();
                            },
                          });
                        }}
                        title="Refund"
                        backgroundColor="colorSecondaryDarker"
                      />
                    ),
                  },
                ]}
              />
              <br />
              <br />
              <div className="title-label">Unconfirmed Tickets</div>
              <DataTable
                dataKey={["eventPendingTransactions", event._id]}
                fetchData={() => listEventsPendingTransactions(event._id)}
                columns={[
                  { title: "Name", selector: (row) => row?.customer?.name },
                  { title: "Phone", selector: (row) => row?.customer?.phone },
                  { title: "Email", selector: (row) => row?.customer?.email },
                  {
                    title: "Time",
                    selector: (row) => (
                      <>
                        <span style={{ fontSize: 8 }}>
                          {dayjs(row.createdAt).format("DD MMM")}
                        </span>{" "}
                        <span style={{ fontWeight: 500, fontSize: 10 }}>
                          {dayjs(row.createdAt).format("hh:mm a")}
                        </span>
                      </>
                    ),
                  },
                  {
                    title: "Seats",
                    selector: (row) => row?.additionalInfo?.ticketSeats,
                  },
                  {
                    title: "Payment Method",
                    selector: (row) => (
                      <div
                        className={`payment-method-cell-${row?.paymentMethod}`}
                      >
                        {row?.paymentMethod}
                      </div>
                    ),
                  },
                  {
                    title: "Screenshot",
                    selector: (row) =>
                      row?.additionalInfo?.screenshotUrl ? (
                        <ActionButton
                          onClick={() => {
                            setSelectedScreenshot(
                              row?.additionalInfo?.screenshotUrl
                            );
                            setIsImageViewerOpen(true);
                          }}
                          title={
                            <div>
                              <span style={{ marginRight: 7 }}>View</span>
                              <FaEye />
                            </div>
                          }
                          backgroundColor="colorPrimaryLight"
                        />
                      ) : (
                        "No Screenshot"
                      ),
                  },
                ]}
                actions={[
                  {
                    component: (row) => (
                      <ActionButton
                        onClick={() =>
                          showPrompt({
                            title: "Confirm Ticket",
                            message: `Are you sure that ${row?.customer?.name} paid ${row?.amount}L.E through ${row?.paymentMethod}?`,
                            onConfirm: async () => {
                              // Confirm the ticket
                              await confirmPaymentTransaction(row?._id);
                              // Invalidate queries in the page
                              await queryClient.invalidateQueries({
                                queryKey: [["eventTickets", event._id]],
                              });
                              await queryClient.invalidateQueries({
                                queryKey: [
                                  ["eventPendingTransactions", event._id],
                                ],
                              });
                              await queryClient.invalidateQueries({
                                queryKey: ["event", eventId],
                              });

                              closePrompt();
                            },
                          })
                        }
                        title="Confirm"
                        backgroundColor="colorPrimaryLight"
                        style={{ marginRight: 10 }}
                      />
                    ),
                  },
                  {
                    component: (row) => (
                      <ActionButton
                        onClick={() => {
                          showPrompt({
                            title: "Refund Ticket",
                            message: `Are you sure you want to reject ticket for ${row?.customer?.name}?`,
                            onConfirm: async () => {
                              // Cancel the transaction
                              await cancelPaymentTransaction(row?._id);
                              // Invalidate queries in the page
                              await queryClient.invalidateQueries({
                                queryKey: [
                                  ["eventPendingTransactions", event._id],
                                ],
                              });
                              await queryClient.invalidateQueries({
                                queryKey: [
                                  ["eventCancelledTransactions", event._id],
                                ],
                              });

                              closePrompt();
                            },
                          });
                        }}
                        title="Reject"
                        backgroundColor="colorSecondaryDarker"
                      />
                    ),
                  },
                ]}
              />
              <br />
              <br />
              <div className="title-label">Cancelled/Refunded Tickets</div>
              <DataTable
                dataKey={["eventCancelledTransactions", event._id]}
                fetchData={() => listEventsCancelledTransactions(event._id)}
                columns={[
                  { title: "Name", selector: (row) => row?.customer?.name },
                  { title: "Phone", selector: (row) => row?.customer?.phone },
                  { title: "Email", selector: (row) => row?.customer?.email },
                  {
                    title: "Time",
                    selector: (row) => (
                      <>
                        <span style={{ fontSize: 8 }}>
                          {dayjs(row.createdAt).format("DD MMM")}
                        </span>{" "}
                        <span style={{ fontWeight: 500, fontSize: 10 }}>
                          {dayjs(row.createdAt).format("hh:mm a")}
                        </span>
                      </>
                    ),
                  },
                  {
                    title: "Seats",
                    selector: (row) => row?.additionalInfo?.ticketSeats,
                  },
                  {
                    title: "Status",
                    selector: (row) => (row?.ticket ? "Refunded" : "Cancelled"),
                  },
                  {
                    title: "Payment Method",
                    selector: (row) => (
                      <div
                        className={`payment-method-cell-${row?.paymentMethod}`}
                      >
                        {row?.paymentMethod}
                      </div>
                    ),
                  },
                  {
                    title: "Screenshot",
                    selector: (row) =>
                      row?.additionalInfo?.screenshotUrl ? (
                        <ActionButton
                          onClick={() => {
                            setSelectedScreenshot(
                              row?.additionalInfo?.screenshotUrl
                            );
                            setIsImageViewerOpen(true);
                          }}
                          title={
                            <div>
                              <span style={{ marginRight: 7 }}>View</span>
                              <FaEye />
                            </div>
                          }
                          backgroundColor="colorPrimaryLight"
                        />
                      ) : (
                        "No Screenshot"
                      ),
                  },
                ]}
              />
            </div>

            {isImageViewerOpen && (
              <ImageViewer
                src={[selectedScreenshot]}
                currentIndex={0}
                disableScroll
                closeOnClickOutside
                backgroundStyle={{ backgroundColor: "rgba(0,0,0,0.5)" }}
                closeComponent={
                  <ActionButton
                    onClick={() => {
                      setIsImageViewerOpen(false);
                      setSelectedScreenshot(null);
                    }}
                    title={<IoClose />}
                    backgroundColor="colorSecondaryDarker"
                  />
                }
                onClose={() => {
                  setIsImageViewerOpen(false);
                  setSelectedScreenshot(null);
                }}
              />
            )}
          </>
        )}
      </GuardProvider>
    </MainDashboardLayout>
  );
};

export default EventTickets;
