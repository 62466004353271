import React, { useEffect, useState } from "react";

import { FaPlus } from "react-icons/fa";

import DataTable from "src/modules/dashboard/components/InfiniteDataTable";
import ActionButton from "../../components/ActionButton";
import {
  createSpacePass,
  editSpacePass,
  getSpacePasses,
} from "src/api/dashboard/space";

import dayjs from "dayjs";
import "./index.scss";

import SearchCustomerModal from "./components/SearchCustomerModal";
import RegisterSpacePassModal from "./components/RegisterSpacePassModal";
import PaymentSpacePassModal from "./components/PaymentSpacePassModal";
import Select from "src/modules/dashboard/components/Select";
import MainDashboardLayout from "src/modules/dashboard/layouts/MainDashboardLayout";
import {
  knowAboutUsSources,
  paymentTransactionTypes,
  queryClient,
  storageKeys,
} from "src/utils";
import _ from "lodash";
import BasicTooltip from "src/modules/dashboard/components/BasicTooltip";
import { GuardProvider } from "src/contexts/GuardContext";

const datePeriods = [
  {
    label: "Today",
    value: {
      start: dayjs().startOf("d").format(),
      end: dayjs().endOf("d").format(),
    },
  },
  {
    label: "Yesterday",
    value: {
      start: dayjs().subtract(1, "d").startOf("d").format(),
      end: dayjs().subtract(1, "d").endOf("d").format(),
    },
  },
  {
    label: "Before Yesterday",
    value: {
      start: dayjs().subtract(2, "d").startOf("d").format(),
      end: dayjs().subtract(2, "d").endOf("d").format(),
    },
  },
  {
    label: "This week",
    value: {
      start: dayjs().startOf("w").subtract(1, "d").format(),
      end: dayjs().endOf("d").format(),
    },
  },
  {
    label: "Last week",
    value: {
      start: dayjs().subtract(1, "w").startOf("w").subtract(1, "d").format(),
      end: dayjs().subtract(1, "w").endOf("w").subtract(1, "d").format(),
    },
  },
  {
    label: "This month",
    value: {
      start: dayjs().startOf("M").format(),
      end: dayjs().endOf("d").format(),
    },
  },
  {
    label: "Last month",
    value: {
      start: dayjs().subtract(1, "M").startOf("M").format(),
      end: dayjs().subtract(1, "M").endOf("M").format(),
    },
  },
  {
    label: "This Year",
    value: {
      start: dayjs().startOf("year").format(),
      end: dayjs().endOf("d").format(),
    },
  },
];

const ReceptionScreen = () => {
  const [selectedSpacePass, setSelectedSpacePass] = useState();

  const getSpacePassDetailsToPay = (row) => {
    setSelectedSpacePass(row);
  };

  const [selectedDate, setSelectedDate] = useState(
    datePeriods.find(
      (d) =>
        d.label ===
        localStorage.getItem(storageKeys.DURATION_RECEPTION_SPACE_PASSES)
    )?.value || datePeriods[0].value
  );

  const [isNewhCustomerModalVisible, setIsNewCustomerModalVisible] =
    useState(false);

  const [isSearchCustomerModalVisible, setIsSearchCustomerModalVisible] =
    useState(false);

  const [isSingleSpacePymentVisible, setIsSingleSpacePymentVisible] =
    useState(false);

  useEffect(() => {
    localStorage.setItem(
      storageKeys.DURATION_RECEPTION_SPACE_PASSES,
      datePeriods.find(
        (d) => JSON.stringify(d.value) === JSON.stringify(selectedDate)
      ).label
    );
  }, [selectedDate]);
  return (
    <MainDashboardLayout
      slug="Reception"
      extraHeaderContent={
        <Select
          style={{ marginLeft: "auto", marginRight: 20, width: 170 }}
          defaultTitle="Select Period"
          isTitleDisabled
          onChange={(value) => {
            setSelectedDate(JSON.parse(value));
          }}
          value={JSON.stringify(selectedDate)}
          options={datePeriods.map((item) => ({
            ...item,
            value: JSON.stringify(item.value),
          }))}
        />
      }
    >
      <GuardProvider allowedUsers={["superAdmin", "receptionist"]}>
        <div className="reception-container">
          <div className="side-reception">
            <button
              className="new-customer-btn"
              onClick={() => setIsSearchCustomerModalVisible(true)}
            >
              New Customer <FaPlus className="white m-l-2" />
            </button>
          </div>
          <div className="table-container">
            <DataTable
              dataKey="spacePasses"
              filters={selectedDate}
              fetchData={getSpacePasses}
              sort={(row1, row2) =>
                dayjs(row1.startTime).isBefore(dayjs(row2.startTime)) ? 1 : -1
              }
              tableStyle={
                {
                  // transform: "scale(0.85)",
                  // transformOrigin: "0% 0%",
                }
              }
              columns={[
                {
                  title: "",
                  selector: (row) => (
                    <span
                      style={{
                        fontSize: 12,
                        color: "var(--primary-color)",
                      }}
                    >
                      #{row?.customer?.customerId ?? "N/A"}
                    </span>
                  ),
                },
                {
                  title: "Name",
                  selector: (row) => row?.customer?.name ?? "Guest",
                  editable: (row) => ({
                    currentValue: row?.customer?.name,
                    type: "text",
                    onEdit: async (newValue) => {
                      if (
                        await editSpacePass({
                          spacePassId: row._id,
                          ...row.customer,
                          name: newValue,
                        })
                      ) {
                        await queryClient.invalidateQueries({
                          queryKey: ["spacePasses"],
                        });
                      }
                    },
                  }),
                },
                {
                  title: "Friends",
                  selector: (row) => <span>{row.friends}</span>,
                  editable: (row) => ({
                    currentValue: row.friends,
                    type: "select",
                    selectOptions: Array.from(Array(25).keys())?.map(
                      (number) => ({
                        label: number,
                        value: number,
                      })
                    ),
                    onEdit: async (newValue) => {
                      if (
                        await editSpacePass({
                          spacePassId: row._id,
                          friends: parseInt(newValue),
                        })
                      ) {
                        await queryClient.invalidateQueries({
                          queryKey: ["spacePasses"],
                        });
                      }
                    },
                  }),
                },
                {
                  title: "Phone",
                  selector: (row) => row?.customer?.phone ?? "N/A",
                  editable: (row) => ({
                    currentValue: row?.customer?.phone,
                    type: "text",
                    onEdit: async (newValue) => {
                      if (
                        await editSpacePass({
                          spacePassId: row._id,
                          ...row.customer,
                          phone: newValue,
                        })
                      ) {
                        await queryClient.invalidateQueries({
                          queryKey: ["spacePasses"],
                        });
                      }
                    },
                  }),
                },
                {
                  title: "Start Time",
                  selector: (row) => (
                    <>
                      <span style={{ fontSize: 12 }}>
                        {dayjs(row.startTime).format("DD MMM")}
                      </span>{" "}
                      <span style={{ fontWeight: 500 }}>
                        {dayjs(row.startTime).format("hh:mm a")}
                      </span>
                    </>
                  ),
                  editable: (row) => ({
                    currentValue: row.startTime,
                    type: "date",
                    onEdit: async (newValue) => {
                      if (
                        await editSpacePass({
                          spacePassId: row._id,
                          startTime: newValue,
                        })
                      ) {
                        await queryClient.invalidateQueries({
                          queryKey: ["spacePasses"],
                        });
                      }
                    },
                  }),
                },
                {
                  title: "End Time",
                  selector: (row) => (
                    <span style={{ color: "#AA7258" }}>
                      {row.endTime ? (
                        <>
                          <span style={{ fontSize: 12 }}>
                            {dayjs(row.endTime).format("DD MMM")}
                          </span>{" "}
                          <span style={{ fontWeight: 500 }}>
                            {dayjs(row.endTime).format("hh:mm a")}
                          </span>
                        </>
                      ) : (
                        "Didn't Leave"
                      )}
                    </span>
                  ),
                },
                {
                  title: "Time Spent",
                  selector: (row) => (
                    <span style={{ color: "#AA7258" }}>
                      {(
                        dayjs(row.endTime ? row.endTime : dayjs()).diff(
                          dayjs(row.startTime),
                          "minutes"
                        ) / 60
                      ).toFixed(0)}
                      <span style={{ fontSize: 10 }}>hrs</span>
                    </span>
                  ),
                },
                {
                  title: "Space Pass",
                  selector: (row) => (
                    <div className="tooltip-container">
                      <BasicTooltip
                        item={
                          <div className="fees-cell">
                            <span style={{ margin: "auto" }}>
                              {row.passAmount ?? 0}
                            </span>
                          </div>
                        }
                        data={
                          row?.paymentTransactions?.filter(
                            (t) =>
                              t.transactionType ===
                              paymentTransactionTypes.SPACE_PASS
                          ).length !== 0
                            ? row.paymentTransactions
                                ?.filter(
                                  (t) =>
                                    t.transactionType ===
                                    paymentTransactionTypes.SPACE_PASS
                                )
                                .map((transaction) => (
                                  <div>
                                    {transaction.amount}{" "}
                                    {transaction.paymentMethod}
                                  </div>
                                ))
                            : null
                        }
                      />
                    </div>
                  ),
                },
                {
                  title: "Kitchen Fees",
                  selector: (row) => (
                    <div className="tooltip-container">
                      <BasicTooltip
                        item={
                          <div className="fees-cell">
                            <span style={{ margin: "auto" }}>
                              {_.sumBy(
                                row.kitchenProducts,
                                (product) => product?.price * product?.quantity
                              )}
                            </span>
                          </div>
                        }
                        data={
                          row.paymentTransactions?.filter(
                            (t) =>
                              t.transactionType ===
                              paymentTransactionTypes.KITCHEN_PURCHASE
                          ).length !== 0
                            ? row.paymentTransactions
                                ?.filter(
                                  (t) =>
                                    t.transactionType ===
                                    paymentTransactionTypes.KITCHEN_PURCHASE
                                )
                                .map((transaction) => (
                                  <div>
                                    {transaction.amount}{" "}
                                    {transaction.paymentMethod}
                                  </div>
                                ))
                            : null
                        }
                      />
                    </div>
                  ),
                },
                {
                  title: "Source",
                  selector: (row) => (
                    <div className={`source-cell-${row.knowAboutUsSource}`}>
                      {row.knowAboutUsSource}
                    </div>
                  ),
                  editable: (row) => ({
                    currentValue: row.knowAboutUsSource,
                    type: "select",
                    selectOptions: Object.entries(knowAboutUsSources).map(
                      ([key, value]) => ({
                        label: `${key
                          .split("_")
                          .map(
                            (w) =>
                              w[0].toUpperCase() + w.substr(1).toLowerCase()
                          )
                          .join(" ")}`,
                        value,
                      })
                    ),
                    onEdit: async (newValue) => {
                      if (
                        await editSpacePass({
                          spacePassId: row._id,
                          knowAboutUsSource: newValue,
                        })
                      ) {
                        await queryClient.invalidateQueries({
                          queryKey: ["spacePasses"],
                        });
                      }
                    },
                  }),
                },
              ]}
              actions={[
                {
                  component: (row) => (
                    <ActionButton
                      title="Pay"
                      backgroundColor="colorPrimaryLight"
                      onClick={() => {
                        setIsSingleSpacePymentVisible(true);
                        getSpacePassDetailsToPay(row);
                      }}
                    />
                  ),
                },
              ]}
              tabs={[
                {
                  title: "Active",
                  filters: {
                    status: "active",
                  },
                },
                {
                  title: "Past",
                  filters: {
                    status: "paid",
                  },
                  disableActions: true,
                },
              ]}
            />
          </div>
        </div>

        <SearchCustomerModal
          isVisible={isSearchCustomerModalVisible}
          setIsVisible={setIsSearchCustomerModalVisible}
          onClickNewCustomer={() => setIsNewCustomerModalVisible(true)}
          onSelectCustomer={async (
            customerId,
            { friends, knowAboutUsSource }
          ) => {
            if (
              await createSpacePass({
                customerId,
                friends,
                knowAboutUsSource,
                startTime: dayjs().toISOString(),
              })
            ) {
              queryClient.invalidateQueries({
                queryKey: ["spacePasses"],
              });
              setIsSearchCustomerModalVisible(false);
            }
          }}
        />

        <RegisterSpacePassModal
          isVisible={isNewhCustomerModalVisible}
          setIsVisible={setIsNewCustomerModalVisible}
        />
        {/* 
      <PaymentSelelctTypeModal
        onClickSingle={() => {
          setIsSingleSpacePymentVisible(true);
        }}
        isVisible={isPayGroupModalVisible}
        setIsVisible={setIsPayGroupModalVisible}
        spacePass={selectedSpacePass}
      /> */}

        {isSingleSpacePymentVisible && (
          <PaymentSpacePassModal
            isVisible={isSingleSpacePymentVisible}
            setIsVisible={setIsSingleSpacePymentVisible}
            spacePass={selectedSpacePass}
          />
        )}
      </GuardProvider>
    </MainDashboardLayout>
  );
};

export default ReceptionScreen;
