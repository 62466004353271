import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getBrandProducts } from "src/api/dashboard/gallery";
import { getKitchenProducts } from "src/api/dashboard/kitchen";
import Input from "src/modules/dashboard/components/Input";
import { notify } from "src/utils";

const SelectGalleryProducts = ({ brandId, onChange }) => {
  const [selectedItems, setSelectedItems] = useState([]);

  const { data: brandProducts } = useQuery({
    queryKey: "brandProducts",
    queryFn: () => getBrandProducts({ brandId }),
  });

  const selectGalleryProducts = (event) => {
    const selectedValue = event.target.value;

    const selectedItemIndex = selectedItems.findIndex(
      (item) => item.productId === selectedValue
    );

    if (selectedItemIndex !== -1) {
      const updatedSelectedItems = [...selectedItems];
      updatedSelectedItems[selectedItemIndex].quantity++;
      setSelectedItems(updatedSelectedItems);
    } else {
      const selectedItem = brandProducts.find(
        (product) => product?._id === selectedValue
      );
      setSelectedItems([
        ...selectedItems,
        {
          productId: selectedItem?._id,
          title: selectedItem.title,
          sellingPrice: selectedItem.sellingPrice,
          quantity: 1,
        },
      ]);
    }
  };

  const incrementQuantity = (index) => {
    const updatedSelectedItems = [...selectedItems];
    if (
      updatedSelectedItems[index].quantity + 1 >
      brandProducts.find(
        ({ _id }) => updatedSelectedItems[index].productId === _id
      ).remainingStock
    ) {
      notify("Not enough stock", "error");
    } else {
      updatedSelectedItems[index].quantity++;
      setSelectedItems(updatedSelectedItems);
    }
  };

  const decrementQuantity = (index) => {
    const updatedSelectedItems = [...selectedItems];
    if (updatedSelectedItems[index].quantity > 1) {
      updatedSelectedItems[index].quantity--;
    } else {
      updatedSelectedItems.splice(index, 1);
    }
    setSelectedItems(updatedSelectedItems);
  };

  useEffect(() => {
    onChange(selectedItems);
  }, [selectedItems, onChange]);

  return (
    <>
      <div>
        <select
          onChange={(event) => {
            selectGalleryProducts(event);
            event.target.value = "";
          }}
          style={{
            width: "100%",
            padding: "8px",
            paddingLeft: "10px",
            backgroundColor: "#EFE6DC",
            border: "0px",
            outline: "0px",
            borderRadius: "4px",
            color: "#6D7254",
            fontFamily: "Aoboshi One",
            fontSize: "16px",
            fontWeight: "light",
          }}
        >
          <option value={""} disabled selected>
            Select products
          </option>
          {brandProducts?.map((item, index) => (
            <option key={index} value={item?._id}>
              {item?.title} - {item?.sellingPrice}
            </option>
          ))}
        </select>

        <div style={{ marginTop: "15px" }}>
          {selectedItems.map(({ title, sellingPrice, quantity }, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "5px",
                fontSize: "14px",
                fontFamily: "Aoboshi One",
              }}
            >
              <div>
                <button
                  style={{
                    border: "0",
                    fontSize: "20px",
                    background: "transparent",
                    margin: " 0 10px",
                    color: "#ddc7ba",
                  }}
                  onClick={() => decrementQuantity(index)}
                >
                  -
                </button>
                {quantity}
                <button
                  style={{
                    border: "0",
                    fontSize: "20px",
                    background: "transparent",
                    margin: " 0 10px",
                    color: "#ddc7ba",
                  }}
                  onClick={() => incrementQuantity(index)}
                >
                  +
                </button>{" "}
                {title}{" "}
              </div>
              <div>{sellingPrice * quantity}</div>
            </div>
          ))}
        </div>
        {selectedItems.length !== 0 && (
          <div>
            <div className="line-separator" />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "5px",
                fontSize: "18px",
                fontFamily: "Aoboshi One",
              }}
            >
              <div>{"Total"}</div>{" "}
              <div>
                {" "}
                {selectedItems.reduce(
                  (acc, curr) => acc + curr.sellingPrice * curr.quantity,
                  0
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SelectGalleryProducts;
