import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { restockKitchenProduct } from "src/api/dashboard/kitchen";
import ActionButton from "src/modules/dashboard/components/ActionButton";
import Input from "src/modules/dashboard/components/Input";
import ModalLayout from "src/modules/dashboard/components/ModalLayout";
import { queryClient } from "src/utils";
const RestockKitchenProductModal = ({ isVisible, setIsVisible, product }) => {
  const currentDate = dayjs();
  const [productDetails, setProductDetails] = useState({
    costPerItem: 0,
    quantity: 0,
    sellingPrice: product?.price,
    date: currentDate.format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
  });

  useEffect(() => {
    setProductDetails({ ...productDetails, sellingPrice: product?.price });
  }, [product]);

  return (
    <>
      <ModalLayout
        show={isVisible}
        onAbort={() => {
          setIsVisible(false);
        }}
        title={`Restock ${product?.title}`}
      >
        <Input
          placeholder={"Cost Per Item"}
          onChange={(e) => {
            setProductDetails({
              ...productDetails,
              costPerItem: parseFloat(e.target.value),
            });
          }}
          name={"costPerItem"}
          trailingLabel={"EGP"}
        />
        <Input
          onChange={(e) => {
            setProductDetails({
              ...productDetails,
              quantity: parseInt(e.target.value),
            });
          }}
          placeholder={"Quantity"}
          name={"quantity"}
        />
        <ActionButton
          onClick={async () => {
            if (await restockKitchenProduct(product._id, productDetails)) {
              await queryClient.invalidateQueries({
                queryKey: ["kitchenProducts"],
              });

              setIsVisible(false);
            }
          }}
          title={"Restock"}
          style={{
            paddingTop: 10,
            paddingBottom: 10,
            fontSize: 16,
            fontFamily: "Aoboshi One",
            margin: "auto",
            marginTop: 30,
          }}
          backgroundColor="colorSecondaryDarker"
        />
      </ModalLayout>
    </>
  );
};

export default RestockKitchenProductModal;
