import _ from "lodash";
import "./index.scss";

const PaymentMethodInput = ({
  value,
  paymentMethod,
  onChange,
  remainingAmount,
  totalAmount,
}) => {
  const handleChange = (e) => {
    const value = parseFloat(e.target.value);
    let newValue = isNaN(value) ? 0 : value;

    // If the value was more than remainingAmount
    if (newValue > remainingAmount) {
      if (newValue === totalAmount) {
        newValue = totalAmount;
      }
    }

    onChange({
      paymentMethod,
      amount: newValue,
      currency: "EGP",
    });
  };

  return (
    <div className="payment-method-input">
      <div className="input-container ">
        <button className="left-button">
          {paymentMethod.charAt(0).toUpperCase() + paymentMethod.slice(1)}
        </button>
        <input
          className="wide-input"
          type="text"
          name={paymentMethod}
          onChange={(e) => handleChange(e)}
          value={value}
          placeholder="0"
        />
        <button
          type="button"
          className="right-button"
          disabled={remainingAmount <= 0 && value === 0}
          style={{
            ...(remainingAmount <= 0 &&
              value === 0 && {
                backgroundColor: "var(--secondary-color-dk1)",
              }),
          }}
          onClick={() => {
            if (value !== 0) {
              // Clear
              onChange({
                paymentMethod,
                amount: 0,
                currency: "EGP",
              });
            } else {
              onChange({
                paymentMethod,
                amount: remainingAmount,
                currency: "EGP",
              });
            }
          }}
        >
          {value === 0 ? "Max" : "Clear"}
        </button>
      </div>
    </div>
  );
};

export default PaymentMethodInput;
