import { createContext, useContext, useState } from "react";
import ActionButton from "src/modules/dashboard/components/ActionButton";
import ModalLayout from "src/modules/dashboard/components/ModalLayout";
import "./index.scss";

const PromptContext = createContext(0);

export const usePrompt = () => useContext(PromptContext);

export const PromptProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [title, setTitle] = useState(null);
  const [message, setMessage] = useState(null);
  const [onConfirm, setOnConfirm] = useState(null);

  const showPrompt = ({ title, message, onConfirm }) => {
    setIsVisible(true);
    setMessage(message);
    setTitle(title);
    setOnConfirm(() => onConfirm);
  };

  const closePrompt = () => {
    setIsVisible(false);
    setTitle(null);
    setMessage(null);
    setOnConfirm(null);
  };

  return (
    <PromptContext.Provider value={{ showPrompt, closePrompt }}>
      <ModalLayout
        show={isVisible}
        title={title || "Confirm"}
        className="prompt-container"
        onAbort={closePrompt}
      >
        <p>{message}</p>
        <div className="actions-container">
          <ActionButton
            title="Cancel"
            onClick={closePrompt}
            backgroundColor="colorSecondaryDarker"
          />
          <ActionButton
            title="Confirm"
            onClick={onConfirm}
            backgroundColor="colorPrimaryLight"
          />
        </div>
      </ModalLayout>
      {children}
    </PromptContext.Provider>
  );
};
