import React from "react";
import Alogo from "src/assets/images/a-logo.png";
import { FaCashRegister } from "react-icons/fa6";
import { BsFillBasketFill } from "react-icons/bs";
import { TbFridge } from "react-icons/tb";
import { MdOutlineMoneyOffCsred } from "react-icons/md";
import { BsCalendarHeartFill } from "react-icons/bs";
import { FaUserFriends } from "react-icons/fa";
import { MdQueryStats } from "react-icons/md";
import { AiOutlineLogout } from "react-icons/ai";

import "./index.scss";
import { Link, useLocation } from "react-router-dom";
import { GuardProvider } from "src/contexts/GuardContext";
import { useAuth } from "src/contexts/AuthContext";

const taps = [
  {
    title: "Reception",
    path: "/portal/reception",
    icon: <FaCashRegister className="icon" />,
    allowedUsers: ["superAdmin", "receptionist"],
  },
  {
    title: "Customers",
    path: "/portal/customers",
    icon: <FaUserFriends className="icon" />,
    allowedUsers: ["superAdmin"],
  },
  {
    title: "Gallery",
    path: "/portal/gallery",
    icon: <BsFillBasketFill className="icon" />,
    allowedUsers: ["superAdmin", "receptionist"],
  },
  {
    title: "Kitchen",
    path: "/portal/kitchen",
    icon: <TbFridge className="icon" />,
    allowedUsers: ["superAdmin", "receptionist"],
  },
  {
    title: "Expenses",
    path: "/portal/expenses",
    icon: <MdOutlineMoneyOffCsred className="icon" />,
    allowedUsers: ["superAdmin", "receptionist"],
  },
  {
    title: "Events",
    path: "/portal/events",
    icon: <BsCalendarHeartFill className="icon" />,
    allowedUsers: ["superAdmin", "receptionist", "eventManager"],
  },
  {
    title: "Statistics",
    path: "/portal/statistics",
    icon: <MdQueryStats className="icon" />,
    allowedUsers: ["superAdmin"],
  },
];

export default function Navbar() {
  const location = useLocation();
  const { logout } = useAuth();

  return (
    <div className="nav-bar-wrapper">
      <div className="nav-bar-container">
        <div className="logo-container">
          <img src={Alogo} alt="" />
        </div>
        {taps.map(({ title, icon, path, allowedUsers }) => (
          <GuardProvider allowedUsers={allowedUsers} replaceWith={<></>}>
            <Link
              to={path}
              className={
                (location.pathname.includes(path) ? "active " : "") +
                "icon-container"
              }
            >
              <h2>{title}</h2>
              {icon}
            </Link>
          </GuardProvider>
        ))}
      </div>
      <div className="logout-container" onClick={logout}>
        <AiOutlineLogout size={20} className="icon" />
      </div>
    </div>
  );
}
