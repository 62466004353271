import { executeWithAuth } from "src/api";
import { API_URL_DASHBOARD, API_URL_SPACE } from "src/utils";

export const getKitchenProducts = () =>
  executeWithAuth({
    method: "GET",
    url: `${API_URL_SPACE}/kitchen/products`,
  }).then((data) => data?.products || []);

export const createKitchenProduct = (productDetails) =>
  executeWithAuth({
    method: "POST",
    notifySuccessMsg: true,
    url: `${API_URL_DASHBOARD}/kitchen/product`,
    body: productDetails,
  }).then((data) => data.product);

export const restockKitchenProduct = (productId, productDetails) =>
  executeWithAuth({
    method: "POST",
    notifySuccessMsg: true,
    url: `${API_URL_DASHBOARD}/kitchen/product/${productId}/restock`,
    body: productDetails,
  }).then((data) => data.product);