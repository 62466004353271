import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getKitchenProducts } from "src/api/dashboard/kitchen";
import Input from "src/modules/dashboard/components/Input";

const SelectKitchenItems = ({
  onChangeKitchenTotalPice,
  onChangeSpacePassPrice,
  spacePassPrice,
  passDuration,
  setKitchenProducts,
  spacePass,
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);

  const { data: kitchenItems } = useQuery({
    queryKey: "kitchenProducts",
    queryFn: getKitchenProducts,
  });
  const selectKitchenProducts = (event) => {
    const selectedValue = event.target.value;

    const selectedItemIndex = selectedItems.findIndex(
      (item) => item._id === selectedValue
    );

    if (selectedItemIndex !== -1) {
      const updatedSelectedItems = [...selectedItems];
      updatedSelectedItems[selectedItemIndex].quantity++;
      setSelectedItems(updatedSelectedItems);
    } else {
      const selectedItem = kitchenItems.find(
        (item) => item._id === selectedValue
      );
      setSelectedItems([
        ...selectedItems,
        {
          _id: selectedItem._id,
          title: selectedItem.title,
          price: selectedItem.price,
          category: selectedItem.category,
          quantity: 1,
        },
      ]);
    }
    setFilteredItems([]);
  };

  useEffect(() => {
    const totalPrice = selectedItems.reduce(
      (acc, { price, quantity }) => acc + parseFloat(price * quantity),
      0
    );
    onChangeKitchenTotalPice(totalPrice);

    setKitchenProducts(selectedItems);
  }, [selectedItems]);

  const incrementQuantity = (index) => {
    const updatedSelectedItems = [...selectedItems];
    updatedSelectedItems[index].quantity++;
    setSelectedItems(updatedSelectedItems);
  };

  const decrementQuantity = (index) => {
    const updatedSelectedItems = [...selectedItems];
    if (updatedSelectedItems[index].quantity > 1) {
      updatedSelectedItems[index].quantity--;
    } else {
      updatedSelectedItems.splice(index, 1);
    }
    setSelectedItems(updatedSelectedItems);
  };

  return (
    <>
      <div>
        <select
          onChange={(event) => {
            selectKitchenProducts(event);
            event.target.value = "";
          }}
          style={{
            width: "100%",
            padding: "8px",
            paddingLeft: "10px",
            backgroundColor: "#EFE6DC",
            border: "0px",
            outline: "0px",
            borderRadius: "4px",
            color: "#6D7254",
            fontFamily: "Aoboshi One",
            fontSize: "16px",
            fontWeight: "light",
          }}
        >
          <option value={""} disabled selected>
            Search for kitchen products
          </option>
          {kitchenItems?.map((item, index) => (
            <option key={index} value={item?._id}>
              {item?.title} - {item?.price}
            </option>
          ))}
        </select>

        <div>
          {filteredItems.map((item, index) => (
            <div key={index} onClick={() => selectKitchenProducts(item?._id)}>
              {item?.title} {item?.price}
            </div>
          ))}
        </div>

        <div style={{ marginTop: "15px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "5px",
              fontSize: "14px",
              fontFamily: "Aoboshi One",
            }}
          >
            SpacePass - {passDuration}
            <div>
              <Input
                value={spacePassPrice === "" ? 0 : spacePassPrice}
                onChange={(e) => {
                  onChangeSpacePassPrice(
                    parseInt(e.target.value === "" ? 0 : e.target.value)
                  );
                }}
              />
            </div>
          </div>

          {selectedItems.map(({ title, price, quantity }, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "5px",
                fontSize: "14px",
                fontFamily: "Aoboshi One",
              }}
            >
              <div>
                <button
                  style={{
                    border: "0",
                    fontSize: "20px",
                    background: "transparent",
                    margin: " 0 10px",
                    color: "#ddc7ba",
                  }}
                  onClick={() => decrementQuantity(index)}
                >
                  -
                </button>
                {quantity}
                <button
                  style={{
                    border: "0",
                    fontSize: "20px",
                    background: "transparent",
                    margin: " 0 10px",
                    color: "#ddc7ba",
                  }}
                  onClick={() => incrementQuantity(index)}
                >
                  +
                </button>{" "}
                {title}{" "}
              </div>
              <div>{price * quantity}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default SelectKitchenItems;
