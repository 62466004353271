import { useEffect, useMemo, useState } from "react";
import { searchSpaceCustomers } from "src/api/dashboard/space";
import ActionButton from "src/modules/dashboard/components/ActionButton";
import Input from "src/modules/dashboard/components/Input";
import ModalLayout from "src/modules/dashboard/components/ModalLayout";
import debounce from "lodash/debounce";
import "./index.scss";
import SimpleLoader from "src/assets/lotties/simple-loader.json";
import Lottie from "react-lottie";
import { knowAboutUsSources } from "src/utils";

const SearchCustomerModal = ({
  isVisible,
  setIsVisible,
  onClickNewCustomer,
  onSelectCustomer,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [friends, setFriends] = useState(0);
  const [knowAboutUsSource, setKnowAboutUsSource] = useState(
    knowAboutUsSources.RETURNING_CUSTOMER
  );

  const handleSearchInput = async (e) => {
    setIsLoading(true);
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
  };

  const searchForCustomers = async () => {
    try {
      const fetchedCustomers = await searchSpaceCustomers({
        searchQuery: searchTerm.trim(),
      });
      setCustomers(fetchedCustomers);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    debounceHandleSearch();
    return () => {
      debounceHandleSearch.cancel();
    };
  }, [searchTerm]);

  const debounceHandleSearch = useMemo(
    () => debounce(searchForCustomers, 400),
    [searchTerm]
  );

  return (
    <>
      <ModalLayout
        show={isVisible}
        onAbort={() => setIsVisible(false)}
        title="Register Space Customer"
      >
        <Input
          value={searchTerm}
          onChange={handleSearchInput}
          name={"name"}
          placeholder="Search by id, name, phone, email"
        />
        <table className="search-results-table">
          {customers?.length !== 0
            ? customers?.map((customer, i) => (
                <tr key={i}>
                  <td>#{customer.customerId}</td>
                  <td>{customer.name}</td>
                  <td>
                    <span className="phone-cell">
                      <span>{customer.countryCode}</span>
                      {customer.phone}
                    </span>
                  </td>
                  <td>
                    <div style={{ position: "relative" }}>
                      <select
                        value={friends}
                        onChange={(e) => setFriends(parseInt(e.target.value))}
                        className="friends-dropdown"
                      >
                        {Array.from(Array(25).keys())?.map((number) => (
                          <option key={number} value={number}>
                            {number}
                          </option>
                        ))}
                      </select>
                      <span
                        style={{
                          fontSize: 6,
                          display: "block",
                          position: "absolute",
                          bottom: 2,
                          left: 6,
                        }}
                      >
                        Friends
                      </span>
                    </div>
                  </td>
                  <td>
                    <ActionButton
                      onClick={async () => {
                        await onSelectCustomer(customer._id, {
                          friends,
                          knowAboutUsSource,
                        });
                      }}
                      style={{
                        paddingTop: 2,
                        paddingBottom: 2,
                        margin: 0,
                      }}
                      title="Select"
                      backgroundColor="colorPrimaryLight"
                    />
                  </td>
                </tr>
              ))
            : isLoading && (
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: SimpleLoader,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                  width={30}
                  height={30}
                />
              )}
        </table>
        <ActionButton
          onClick={() => {
            setIsVisible(false);
            onClickNewCustomer();
          }}
          title="New Customer"
          style={{
            paddingTop: 10,
            paddingBottom: 10,
            fontSize: 16,
            fontFamily: "Aoboshi One",
            margin: "auto",
          }}
          backgroundColor="colorSecondaryDarker"
        />
      </ModalLayout>
    </>
  );
};
export default SearchCustomerModal;
