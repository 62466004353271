import dayjs from "dayjs";
import "./index.scss";
import ActionButton from "src/modules/dashboard/components/ActionButton";
import { brandSaleStatuses } from "src/utils/constants";
import { returnBrandSale } from "src/api/dashboard/gallery";
import { queryClient } from "src/utils";
import { usePrompt } from "src/contexts/PromptContext";

const SaleBox = ({
  _id,
  customer,
  saleId,
  date,
  items,
  paymentTransactions,
  total,
  status,
  brandId,
}) => {
  const { showPrompt, closePrompt } = usePrompt();

  return (
    <div className="sale-box-container">
      <div className="left-wrapper">
        <div className="header">
          <div className="sale-id">#{saleId}</div>
          <div className="sale-date">
            {dayjs(date).format("dd DD/MM/YYYY hh:mma")}
          </div>
        </div>
        <div className="items">
          {items?.map(({ quantity, title, sellingPrice }) => (
            <div className="item-row">
              <span>{quantity}x</span>
              <span>{title}</span>
              <span>{sellingPrice * quantity}L.E</span>
            </div>
          ))}
        </div>
        <div className="line-separator" />
        <div className="footer">
          <h5>Total</h5>
          <div>
            <span className="payment-method">
              {paymentTransactions?.[0]?.paymentMethod}
            </span>
            <span className="total">{total}L.E</span>
          </div>
        </div>
      </div>
      <div className="right-wrapper">
        <div className="customer-info">
          <div>
            <p>{customer?.name}</p>
            <span>#{customer?.customerId}</span>
          </div>
          <p>{customer?.phone}</p>
        </div>
        {status === brandSaleStatuses.PAID ? (
          <ActionButton
            title="Return"
            onClick={async () => {
              showPrompt({
                title: "Return Sale",
                message: `Are you sure you want to return sale #${saleId} with amount of ${total} L.E`,
                onConfirm: async () => {
                  if (await returnBrandSale(_id)) {
                    await queryClient.invalidateQueries({
                      queryKey: ["galleryBrandSales", brandId],
                    });
                    await queryClient.invalidateQueries({
                      queryKey: ["brandProducts", { brandId }],
                    });
                    await queryClient.invalidateQueries({
                      queryKey: ["galleryStatistics", brandId],
                    });

                    closePrompt();
                  }
                },
              });
            }}
            backgroundColor="colorSecondaryDarker"
          />
        ) : (
          <p className="sale-status-label">Returned</p>
        )}
      </div>
    </div>
  );
};

export default SaleBox;
