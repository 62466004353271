import React, { useEffect, useState } from "react";
import RightVectorWithTitle from "src/assets/images/right-vector-with-aura.png";
import LeftVectorWithTitle from "src/assets/images/Left-top-with-aura.png";
import PostImg from "src/assets/images/post-img.png";
import { QRCode } from "react-qrcode-logo";
import { Helmet } from "react-helmet";

import "./index.scss";
import { useParams } from "react-router-dom";
import Loader from "src/modules/space/components/Loader";
import { getEventTicketDetails } from "src/api/space/events";
import dayjs from "dayjs";
export default function TicketDetails() {
  const { ticketId, eventId } = useParams();
  const [userTicketDetails, setUserTicketDetails] = useState();

  useEffect(() => {
    (async () => {
      const ticket = await getEventTicketDetails({ eventId, ticketId });
      setUserTicketDetails(ticket);
    })();
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{userTicketDetails?.event?.title}</title>
        <meta
          property="title"
          content={userTicketDetails?.event?.title}
          data-rh="true"
        />
        <meta
          property="description"
          content={userTicketDetails?.event?.description}
          data-rh="true"
        />
        <meta property="image" content="/post-img.png" data-rh="true" />
      </Helmet>
      {userTicketDetails ? (
        <div className="ticket-details text-center">
          <div className="right-vector-with-aura  ">
            <img src={RightVectorWithTitle} alt="" />
          </div>
          <div className="left-vector-with-aura-payment ">
            <img src={LeftVectorWithTitle} alt="" />
          </div>

          <div>
            <h1>Your Ticket is Ready</h1>
            <h5 className="any-help">
              If you need any help, please contact us at 01226568908
            </h5>
            <div className="ticket-container m-auto">
              <h5>{userTicketDetails?.event?.title}</h5>
              {/* <div style={{ marginTop: 40, marginBottom: 20 }}>
                <img
                  src="https://i.ibb.co/2d0Y7NP/aura-logo.jpg"
                  alt="Aura Space"
                  style={{
                    width: 80,
                    height: 80,
                  borderRadius: 100,
                    boxShadow: "-2px 2px 23px 0px rgba(0,0,0,0.1)",
                  }}
                />
                <span
                  style={{
                    fontSize: 20,
                    color: "var(--primary-color)",
                    marginLeft: 20,
                    marginRight: 20,
                  }}
                >
                  x
                </span>
                <img
                  src="https://i.ibb.co/d4SLSpB/curls-en-route.jpg"
                  alt="Aura Space"
                  style={{
                    width: 80,
                    height: 80,
                    borderRadius: 100,
                    boxShadow: "-2px 2px 23px 0px rgba(0,0,0,0.1)",
                  }}
                />
              </div> */}
              <h2 className=" fw-bolder  my-4">
                Hi, {userTicketDetails.customer.name}
              </h2>
              <h4 className=" fw-bolder  my-4">
                The event starts{"   "}
                <span style={{ color: "#3F4631" }}>
                  {dayjs(userTicketDetails?.event?.date).format("HH:mm a")}
                </span>
                {"   "}
                {dayjs(userTicketDetails?.event?.date).format("dddd, D MMMM")}
              </h4>

              <h2 className=" fw-light  mb-4">
                Your Ticket Number #{userTicketDetails.ticketId}
              </h2>
              <div className="row">
                <div className="col-md-6 d-flex justify-content-start align-items-center">
                  <div className="circle-container me-md-2 me-1">
                    <h3>{userTicketDetails.ticketSeats}</h3>
                  </div>
                  <h6 className=" mt-2">
                    Event Ticket x {userTicketDetails.event?.price} L.E
                  </h6>
                </div>
                <div className="col-md-6 d-flex justify-content-between align-items-center mt-2">
                  <h6 className=" ms-md-5 ms-1 ">Total : </h6>
                  <h6 className=" text-black fs-5 ml-10">
                    {userTicketDetails.event?.price *
                      userTicketDetails?.ticketSeats}{" "}
                    L.E
                  </h6>
                </div>
              </div>
              <div className="qr-code mt-4">
                <p>Please keep a screenshot of this QR code</p>
                <QRCode
                  value={window.location.href}
                  enableCORS
                  size={200}
                  bgColor="#DDC7BA1A"
                  fgColor="#6D7254"
                  logoImage="/a-logo.png"
                  removeQrCodeBehindLogo
                  logoPadding={5}
                  logoPaddingStyle="circle"
                  qrStyle="dots"
                  eyeRadius={[
                    {
                      // top/left eye
                      outer: [10, 10, 0, 10],
                      inner: [0, 10, 10, 10],
                    },
                    [10, 10, 10, 0], // top/right eye
                    [10, 0, 10, 10], // bottom/left
                  ]}
                />
              </div>
              <div className="wide-dash-line my-4 "></div>
              <div className="post-img">
                {userTicketDetails?.event?.imageUrl && (
                  <img
                    src={userTicketDetails?.event?.imageUrl}
                    alt={userTicketDetails?.event?.title}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}
