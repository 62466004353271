import StatsNumberBox from "src/modules/dashboard/components/StatsNumberBox";
import "./index.scss";
import { useQuery } from "react-query";
import { getBrandStatistics } from "src/api/dashboard/gallery";

const StatisticsSection = ({ brandId }) => {
  const { data: statistics } = useQuery({
    queryKey: ["galleryStatistics", brandId],
    queryFn: () => getBrandStatistics(brandId),
  });
  return (
    <div className="stats-boxes-container">
      <StatsNumberBox
        title={"Sales"}
        subTitle={"This Month"}
        mainNumber={statistics?.thisMonthSales ?? 0}
        numberIdentifier={"L.E"}
        comparedNumber={
          statistics?.thisMonthSales - statistics?.lastMonthSales ?? 0
        }
        comparedText={"last month"}
      />
      <StatsNumberBox
        title={"Sales"}
        subTitle={"Last Month"}
        mainNumber={statistics?.lastMonthSales ?? 0}
        numberIdentifier={"L.E"}
        comparedNumber={
          statistics?.lastMonthSales - statistics?.beforeLastMonthSales ?? 0
        }
        comparedText={"month before"}
      />
      <StatsNumberBox
        title={"Current Stock"}
        subTitle={"All Time"}
        mainNumber={statistics?.currentStock ?? 0}
        numberIdentifier={"L.E"}
      />
      <StatsNumberBox
        title={"Paid"}
        subTitle={"All Time"}
        mainNumber={statistics?.paidPayments ?? 0}
        numberIdentifier={"L.E"}
      />
      <StatsNumberBox
        title={"Current Balance"}
        subTitle={"All Time"}
        mainNumber={statistics?.currentBalance ?? 0}
        numberIdentifier={"L.E"}
      />
    </div>
  );
};

export default StatisticsSection;
