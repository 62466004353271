import { execute } from "src/api";
import { API_URL_DASHBOARD } from "src/utils";

export const login = ({ username, password }) =>
  execute({
    method: "POST",
    url: `${API_URL_DASHBOARD}/auth/login`,
    body: {
      username,
      password,
    },
  });
