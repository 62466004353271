import React from "react";
import RightVectorWithTitle from "src/assets/images/right-vector-with-aura.png";
import LeftVectorWithTitle from "src/assets/images/Left-top-with-aura.png";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { listEventTickets } from "src/api/space/events";
import DataTable from "src/modules/dashboard/components/InfiniteDataTable";
import "./index.scss";

const EventTickets = () => {
  const { eventId } = useParams();
  const { data } = useQuery(["eventTickets"], async () =>
    listEventTickets({ eventId })
  );

  return (
    <div className="PaymentPage text-center ">
      <div className="right-vector-with-aura  ">
        <img src={RightVectorWithTitle} alt="" />
      </div>
      <div className="left-vector-with-aura-payment ">
        <img src={LeftVectorWithTitle} alt="" />
      </div>
      {data?.length !== 0 && (
        <h3 style={{ color: "var(--secondary-color)" }}>
          {data?.[0]?.event?.title}
        </h3>
      )}
      <div
        style={{
          backgroundColor: "var(--secondary-color)",
          margin: "150px 100px 70px",
        }}
      >
        <DataTable
          dataKey="eventTickets"
          filters={{ eventId }}
          fetchData={listEventTickets}
          limit={1000}
          columns={[
            { title: "" },
            {
              title: "Name",
              selector: (row) => row?.customer?.name,
            },
            {
              title: "Email",
              selector: (row) => row?.customer?.email,
            },
            {
              title: "Phone",
              selector: (row) => row?.customer?.phone,
            },
            {
              title: "Tickets",
              selector: (row) => row?.ticketSeats,
            },
            {
              title: "Ticket Number",
              selector: (row) => row?.ticketId,
            },
            {
              title: "Payment Method",
              selector: (row) => row?.paymentTransaction?.paymentMethod,
            },
          ]}
        />
      </div>
      {data && (
        <>
          <h3 style={{ color: "var(--secondary-color)" }}>
            Total Tickets:{" "}
            {data?.reduce((acc, curr) => acc + curr.ticketSeats, 0)}
          </h3>
          <h3 style={{ color: "var(--secondary-color)" }}>
            Total Amount:{" "}
            {data?.reduce(
              (acc, curr) => acc + curr.paymentTransaction.amount,
              0
            )}
          </h3>
        </>
      )}
    </div>
  );
};

export default EventTickets;
