import { execute } from "src/api";
import { API_URL_DASHBOARD } from "src/utils";

export const listEvents = ({ paginationToken, limit }) =>
  execute({
    method: "GET",
    url: `${API_URL_DASHBOARD}/events`,
    query: { paginationToken, limit },
  }).then((data) => data?.events);

export const getEventDetails = (eventId) =>
  execute({
    method: "GET",
    url: `${API_URL_DASHBOARD}/events/${eventId}`,
  });

export const listEventTickets = (_id) =>
  execute({
    method: "GET",
    url: `${API_URL_DASHBOARD}/events/${_id}/tickets`,
  }).then((data) => data.tickets);

export const listEventsPendingTransactions = (_id) =>
  execute({
    method: "GET",
    url: `${API_URL_DASHBOARD}/events/${_id}/pending`,
  }).then((data) => data.pendingTransactions);

export const listEventsCancelledTransactions = (_id) =>
  execute({
    method: "GET",
    url: `${API_URL_DASHBOARD}/events/${_id}/cancelled`,
  }).then((data) => data.cancelledTransactions);
